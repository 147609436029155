<template>
  <div class="page-wrapper bg-blue-dark-500 py-2">
    <div class="pt-5 p-3 text-center">
      <!-- <img src="../assets/logo.png" alt="logo" /> -->
      <img
        :src="$appLogo"
        alt="logo"
        style="height: 87px;object-fit: contain;"
      />
    </div>

    <b-row class="no-gutters justify-content-center my-5">
      <b-card no-body style="max-width: 440px" class="w-100 mx-3 border-0">
        <b-card-body class="p-4">
          <h3 class="text-center text-primary font-weight-normal mb-1">
            {{ $t("page.passwordRecovery.forgotPassword") }}
          </h3>
          <h6 class="text-center text-uppercase text-weight-bold mb-4">
            {{ $t("page.passwordRecovery.subtitle") }}
          </h6>
          <validation-observer
            ref="observer"
            v-slot="{ handleSubmit, invalid }"
          >
            <b-form @submit.stop.prevent="handleSubmit(recoverUserPassword)">
              <form-input
                v-model="email"
                class="input-field"
                :placeholder="$t('page.passwordRecovery.yourEmail')"
                veeName="email"
                :veeRules="{ required: true, email: true }"
                @input="email = email.toLowerCase()"
              />
              <b-row
                class="no-gutters justify-content-between align-items-center pt-3"
              >
                <b-col class="col-12 col-sm-auto">
                  <b-button
                    variant="primary"
                    class="text-white text-uppercase"
                    :disabled="invalid"
                    block
                    type="submit"
                  >
                    {{ $t("button.send") }}
                  </b-button>
                </b-col>
                <b-col class="col-12 col-sm-auto mt-3 mt-sm-0 text-center">
                  <b-link @click="goToLogin">
                    {{ $t("button.backToLogin") }}
                  </b-link>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-row>
    <alert />
    <loader />
  </div>
</template>

<script>
import Alert from "./../../../console-components/BaseAlert";
import Loader from "./../../../console-components/BaseLoader";
import FormInput from "./../../../console-components/BaseFormInput";
import { appMixin } from "../mixins";
export default {
  mixins: [appMixin],
  components: {
    Loader,
    Alert,
    FormInput
  },
  data() {
    return {
      email: ""
    };
  },
  methods: {
    async recoverUserPassword() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("auth/recoverUserPassword", this.email);

        this.$store.commit("alert/SET_ALERT", {
          type: "success",
          titleText: "page.passwordRecovery.successTitle",
          messageText: "page.passwordRecovery.successMsg"
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    goToLogin() {
      this.$router.push({ name: "Login" });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100vh);
}
.input-field {
  /deep/ {
    .form-control {
      background-color: var(--gray-light);
    }
  }
}
.card-footer {
  border-top: unset;
  padding: 8px;
  background-color: var(--blue-dark-400);
  .b-link {
    opacity: 0.7;
  }
}
</style>
